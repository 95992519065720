<template>
  <div>
    <p class="text-xl text-grey-light font-medium font-headers mb-3 font-bold text-grey-dark-3">
      Platform Open Assessments
    </p>
    <div class="bg-white px-2 pt-3 pb-4 rounded shadow">
      <p class="mb-2 font-bold font-header text-grey-light text-mdl">
        Upcoming and Ongoing Assessments
      </p>
      <div class="mb-2 flex space-x-2">
        <div class="w-1/4">
          <NewSelect
          label="Organization"
          :options="organizationOptions"
          :value="selectedOrganization"
          :onSelect="setSelectedOrganization"
          :preselectFirst="true"
          />
        </div>
        <div class="w-1/5">
         <NewSelect
          label="Assessment State"
          :options="stateOptions"
          :value="selectedState"
          :onSelect="setSelectedState"
          :preselectFirst="true"
          />
        </div>
      </div>
      <div class="divide-y-2 divide-grey-light-1 px-2">
        <div />
        <div v-if="!mappedList.length" class="py-2 font-semibold text-grey-light text-mdl">
          No upcoming or ongoing assessments for the current filters
        </div>
        <div v-if="mappedList.length" v-for="(assessment, index) in mappedList" :key="index">
          <CompanyAssessmentCard :assessment="assessment" :isPlatformAssessment="true"/>
        </div>
        <div />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import CompanyAssessmentCard from "../components/CompanyAssessmentCard.vue";
import NewSelect from "@/components/Select/NewSelect.vue";

export default {
  name: "OpenAssessmentsManagement",
  components: { CompanyAssessmentCard, NewSelect },
  data: () => ({
    mappedList: [],
    organizationOptions: [
      { label: "All", value: 0 },
    ],
    selectedOrganization:  { label: "All", value: 0 },
    stateOptions: [
      { label: "All", value: 0 },
      { label: "Not Started", value: 1 },
      { label: "In Progress", value: 2 },
    ],
    selectedState:  { label: "All", value: 0 },
  }),
  methods: {
    ...mapActions({
      getPlatformActiveAssessments: "companies/getPlatformActiveAssessments",
    }),
    setSelectedOrganization(val) {
      this.selectedOrganization = val;
    },
    setSelectedState(val) {
      this.selectedState = val;
    },
    setFilteredAssessments() {
      this.mappedList = this.allAssessments.filter(x => 
        (!this.selectedOrganization.value || (x.organizationId === this.selectedOrganization.value)) &&
        (!this.selectedState.value || (x.state === this.selectedState.value))
      );
    }
  },
  computed: {
    ...mapState({
      allAssessments: (state) => state.companies.platformActiveAssessments,
    }),
  },
  watch: {
    allAssessments() {
      this.setFilteredAssessments();
      this.organizationOptions = [
      { label: "All", value: 0 },
      ...this.allAssessments
        .map(x => ({ label: x.organizationName, value: x.organizationId }))
        .filter((item, index, self) => 
          index === self.findIndex((t) => t.value === item.value)
        )
    ];
    },
    selectedState() {
      this.setFilteredAssessments();
    },
    selectedOrganization() {
      this.setFilteredAssessments();
    }
  },
  async mounted() {
    await this.getPlatformActiveAssessments()
  }
};
</script>
