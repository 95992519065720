<template>
  <div class="py-2 flex items-center justify-between">
    <div>
      <p v-if="isPlatformAssessment" class="text-grey-dark-1 text-mdlh">
        {{ assessment.organizationName }}
      </p>
      <p class="font-medium text-grey-dark-1 text-mdlh">
        {{ assessment.name }}
        {{ displayVersionForSeller(productVersion) }}
      </p>
      <div class="flex items-center mt-1 space-x-3.5">
        <div class="w-100">
          <p class="text-grey-light text-sm-2 mb-0.4">Launch Date</p>
          <p class="text-grey-light text-sm-2 font-semibold">
            {{ formatDate(assessment.openingTime) }}
          </p>
        </div>
        <div class="w-100">
          <p class="text-grey-light text-sm-2 mb-0.4">Close Date</p>
          <p class="text-grey-light text-sm-2 font-semibold">
            {{ formatDate(assessment.closingTime) }}
          </p>
        </div>
        <p
          class="text-sm-2 border rounded p-0.5 align-middle"
          :class="status.class"
        >
          {{ status.text }}
        </p>
      </div>
    </div>
    <div class="flex space-x-2">
      <Button
        text="Manage Communication"
        type="secondary"
        size="medium"
        :iconLeft="email"
        @click.native="manage"
      />
      <Button
        text="Edit"
        type="secondary"
        size="medium"
        :iconLeft="edit"
        @click.native="editAssessment"
      />
      <Button
        v-if="isPlatformAssessment"
        text="View Company"
        type="secondary"
        size="medium"
        :iconLeft="email"
        @click.native="viewCompany"
      />
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import Button from '../../../components/Button/Button.vue';
import email from '@/assets/img/icons/email.svg';
import edit from '@/assets/img/icons/edit.svg';
import { mapActions, mapState, mapGetters } from 'vuex';

export default {
  components: { Button },
  name: 'CompanyAssessmentCard',
  data: () => ({ email, edit }),
  props: { assessment: { type: Object }, isPlatformAssessment: {type: Boolean, default: false} },
  computed: {
    ...mapState({ 
      currentCompany: (state) => state.companies.currentCompany 
    }),
    ...mapGetters({
      isImpersonating: "auth/isImpersonating",
      isImpersonating: "auth/isSeller",
    }),
    productVersion(){
      return this.assessment.productVersion;
    },
    status() {
      switch (this.assessment.state) {
        case 0:
          return { text: 'Not Launched', class: 'text-red border-red' };
        case 1:
          return {
            text: 'Not Started',
            class: 'text-orange border-orange',
          };
        case 2:
          return {
            text: 'In Progress',
            class: 'text-blue border-blue',
          };
        default:
          return {
            text: 'Completed',
            class: 'text-green-2 border-green-2',
          };
      }
    },
  },
  methods: {
    ...mapActions({
      setSelectedAssessment: 'companies/setSelectedAssessment',
      continueDraft: 'assessmentSetup/continueDraft',
      setAssessmentTitle: 'assessmentSetup/setAssessmentTitle',
      setFlowMode: 'assessmentSetup/setFlowMode',
      setShouldEdit: 'assessmentSetup/setShouldEdit'
    }),
    formatDate(date) {
      return moment(date).format('ddd, D MMM YYYY');
    },
    manage() {
      this.setSelectedAssessment({ assessment: this.assessment });
      this.$router.push(
        `/company/${this.currentCompany.id}/assessments/${this.assessment.id}/communication`
      );
    },
    viewCompany() {
      this.$router.push(`/company/${this.assessment.organizationId}/overview`);
    },
    displayVersionForSeller(version) {
      if (version &&  version !== "" && (this.isImpersonating || this.isSeller)) 
        return " (v" + version + ")";
      return "";
    },
    async editAssessment() {
      var mode = '';
      switch (this.assessment.state) {
        case 1:
          mode = 'edit-launched';
          break;
        case 2:
          mode = 'edit-started';
          break;
        default:
          mode = 'edit-draft';
          break;
      }
      this.setShouldEdit(true);
      this.setFlowMode(mode);
      this.setAssessmentTitle({ title: this.assessment.name });
      await this.continueDraft({ id: this.assessment.id });
      this.$router.push('/assessments/general-information');
    },
  },
};
</script>
